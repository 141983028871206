<script setup lang="ts">
// icons
import { faMapMarked, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { faMapPin, faTimesCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { searchUiGuestAdult, searchUiGuestChild } from '@/locales/fixtures';

// components
import CiLocationInput from '@/components/CiSearch/Location/CiLocationInput.vue';
// import CiSkeletonDatepicker from '@/components/skeleton/CiSkeletonDatepicker.vue';

// /********************
//  * PROPS & EMITS     *
//  ********************/
const emit = defineEmits<{
  close: [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const getRouteBaseName = useRouteBaseName();
const loadingStore = useLoadingStore();
const route = useRoute();
const searchStore = useSearchStore();
const { $gettext, $pgettext } = useGettext();
const { locale } = useI18n();

const locationInputRef = ref<InstanceType<typeof CiLocationInput> | null>(null);
const contentTransition = ref('slide-next');
const {
  ages,
  closeOpenType,
  datePickerValues,
  getChildAge,
  hasCampsite,
  hasLocation,
  i18nPromoCampsitesTitle,
  i18nSuggestionTitle,
  localChildrenCount,
  onAccommodationChange,
  onCampingCardChange,
  onChildAgeChange,
  onDateChange,
  onDateClear,
  onFlexChange,
  onLocationChange,
  onLocationClear,
  onLocationInputFocus,
  onLocationSave,
  onLocationSelected,
  onPersonCountChange,
  onSave,
  onSearchSubmit,
  openOpenType,
  pointer,
  showSuggestions,
} = useSearch({
  contentTransition,
});

const {
  i18nBenefitCardHeadline,
  i18nFlexDayOne,
  i18nFlexDayThree,
  i18nFlexDayTwo,
  i18nFlexHeadline,
  i18nLabelPitch,
  i18nLabelRentals,
  i18nResetSelection,
} = useI18nSearchAvailabilities();

/********************
 * REFS & VARS       *
 ********************/
// const CiDatepicker = defineAsyncComponent({
//   loader: () => import('@/components/CiDatepicker/CiDatepicker.vue'),
//   loadingComponent: CiSkeletonDatepicker,
// });

// computed: {
//   datePickerConfig() {
//     return {
//       align: 'left',
//       birthdateMode: false,
//       breakpoint: 800,
//       closeAfterSelect: false,
//       dateFormat: 'yyyy-MM-dd',
//       dateFormatUseRange: true,
//       displayMonths: 2,
//       inline: true,
//       maxDate: null,
//       minDate: new Date(),
//       minDays: 2,
//       openTopOffset: 2,
//       range: true,
//       selectStyle: 'day',
//       showTooltip: true,
//       i18n: {
//         inputFormat: 'MM-dd-yyyy',
//         nights: [this.$gettext('night'), this.$gettext('nights')],
//         until: this.$gettext('until'),
//         today: this.$gettext('today'),
//         placeholder: '',
//         inputPlaceholder: '',
//       },
//       pickerWidthPixelOffset: -1,
//     };
//   },
// },

/********************
 * WATCHER           *
 ********************/
// set focus on location input
watch(
  () => locationInputRef.value,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        newValue.setFocus();
      });
    }
  },
);

/********************
 * HOOKS             *
 ********************/
onMounted(() => {
  if (searchStore.searchOpenType === 'location') {
    searchStore.getSuggestions({ lang: locale.value, query: searchStore.searchParams.q });
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function onClose() {
  closeOpenType();
  emit('close');
}

function onPrevious(openType) {
  contentTransition.value = 'slide-prev';

  switch (openType) {
    case 'date':
      openOpenType('location');
      break;
    case 'accommodation':
      openOpenType('date');
      break;
    case 'traveller':
      openOpenType('accommodation');
      break;
  }
}

function onNext(openType) {
  onSave(openType);
}

function onSkip(openType) {
  contentTransition.value = 'slide-next';

  switch (openType) {
    case 'location':
      openOpenType('date');
      break;
    case 'date':
      openOpenType('accommodation');
      break;
    case 'accommodation':
      openOpenType('traveller');
      break;
  }
}

function onSubmit(openType) {
  onSave(openType);
  onSearchSubmit();
  emit('close');
}
</script>

<template>
  <form
    class="search-modal flex h-full flex-col bg-white"
    novalidate
    @keypress.enter.prevent="onSave(searchStore.searchOpenType)"
  >
    <div class="search-modal__header py-4">
      <div class="flex items-center justify-between">
        <CiButton
          class="button"
          :class="{
            invisible:
              searchStore.searchOpenType === 'location'
              || (searchStore.searchOpenType === 'date' && getRouteBaseName(route) === RBN_CAMPSITE_DETAIL),
          }"
          icon-before
          @click="onPrevious(searchStore.searchOpenType)"
        >
          <span class="hidden">{{
            $pgettext('Search modal action step back - will not be displayed', 'Step Back')
          }}</span>
        </CiButton>

        <transition
          :name="contentTransition"
          mode="out-in"
        >
          <!-- location -->
          <div
            v-if="searchStore.searchOpenType === 'location'"
            key="location"
          >
            <div class="m-0 font-medium">
              {{ $pgettext('Search flyout headline location', 'Where do you want to camp?') }}
            </div>
          </div>

          <!-- date -->
          <div
            v-else-if="searchStore.searchOpenType === 'date'"
            key="date"
          >
            <div class="m-0 font-medium">
              {{ $pgettext('Search flyout headline date', 'When do you want to camp?') }}
            </div>
          </div>

          <!-- accommodation -->
          <div
            v-else-if="searchStore.searchOpenType === 'accommodation'"
            key="accommodation"
          >
            <div class="m-0 font-medium">
              {{ $pgettext('Search flyout headline accommodation', 'How would you like to camp?') }}
            </div>
          </div>

          <!-- traveller -->
          <div
            v-else-if="searchStore.searchOpenType === 'traveller'"
            key="traveller"
          >
            <div class="m-0 font-medium">
              {{ $pgettext('Search flyout headline traveller', 'How many people would like to camp?') }}
            </div>
          </div>
        </transition>

        <CiButton
          class="button"
          icon-ratio="0.8"
          :icon="faTimesCircle"
          @click.prevent="onClose"
        >
          <span class="hidden">{{ $gettext('Close') }}</span>
        </CiButton>
      </div>
    </div>

    <div class="search-modal__content">
      <transition
        :name="contentTransition"
        mode="out-in"
      >
        <!-- location -->
        <div
          v-if="searchStore.searchOpenType === 'location'"
          key="location"
          class="my-4"
        >
          <div class="mx-4 pb-2">
            <CiLocationInput
              ref="locationInputRef"
              :search-string="searchStore.searchParams.q"
              :is-submit="searchStore.searchTouched && route.name !== 'homeLanguage'"
              @focus="onLocationInputFocus"
              @change="onLocationChange"
              @clear="onLocationClear"
              @submit="onLocationSave(emit)"
            />
          </div>

          <CiSearchResultList
            v-if="showSuggestions && hasLocation"
            :active="pointer"
            :results="searchStore.suggestions.locations"
            :title="''"
            :icon="faMapMarked"
            title-prop="name"
            subtitle-prop="subtitle"
            @search-result-list-click="onLocationSelected($event, emit)"
          />

          <CiSearchResultList
            v-if="
              showSuggestions
                && hasCampsite
                && !(loadingStore.isLoading('getSuggestions') && !searchStore.suggestions.locations.length)
            "
            :active="pointer"
            :index-offset="searchStore.suggestions.locations.length"
            :results="searchStore.suggestions.campsites"
            :title="searchStore.searchParams.q.length > 2 ? i18nSuggestionTitle : i18nPromoCampsitesTitle"
            :icon="faMapPin"
            :no-results-message="true"
            title-prop="name"
            subtitle-prop="campsite"
            image-prop="icon_8_5"
            @search-result-list-click="onLocationSelected($event, emit)"
          />
        </div>

        <!-- date -->
        <div
          v-else-if="searchStore.searchOpenType === 'date'"
          key="date"
          class="m-4"
        >
          <LazyCiDatepicker
            :value="datePickerValues"
            @change="onDateChange"
          />
          <div class="flex flex-col gap-4 divide-y divide-gray-30 py-4">
            <transition
              name="translate-y-up"
              mode="out-in"
            >
              <div
                v-if="datePickerValues[0] && datePickerValues[1]"
                class="flex flex-col gap-4"
              >
                <div>
                  <div class="m-0 font-medium">
                    {{ i18nFlexHeadline }}
                  </div>
                  <div class="flex-days-container flex flex-wrap justify-between pt-1 sm:flex-nowrap">
                    <CiFilterBoolean
                      class="m-0 mr-1 w-full whitespace-nowrap"
                      :value="searchStore.searchParams.flex === 1"
                      :name="'1'"
                      :label="i18nFlexDayOne"
                      @input="onFlexChange($event, 1)"
                    />
                    <CiFilterBoolean
                      class="m-0 mx-1 w-full whitespace-nowrap"
                      :value="searchStore.searchParams.flex === 2"
                      :name="'2'"
                      :label="i18nFlexDayTwo"
                      @input="onFlexChange($event, 2)"
                    />
                    <CiFilterBoolean
                      class="m-0 ml-1 w-full whitespace-nowrap"
                      :value="searchStore.searchParams.flex === 3"
                      :name="'3'"
                      :label="i18nFlexDayThree"
                      @input="onFlexChange($event, 3)"
                    />
                  </div>
                </div>
                <div>
                  <div class="m-0 font-medium">
                    {{ i18nBenefitCardHeadline }}
                  </div>
                  <div class="flex justify-between pt-1">
                    <CiFilterBoolean
                      class="m-0 mr-1"
                      :value="searchStore.isCampingCardSearch"
                      name="campingcard"
                      label="CampingCard"
                      @input="onCampingCardChange($event)"
                    >
                      <CiCampingCardLogo class="mr-2" />
                    </CiFilterBoolean>
                  </div>
                </div>
              </div>
            </transition>

            <div v-if="datePickerValues[0] || datePickerValues[1]">
              <button
                class="button button--danger-outline mt-4"
                type="button"
                @click="onDateClear"
              >
                <CiAwesomeIcon
                  :icon="faTrashAlt"
                  ratio="0.8"
                />
                <span>{{ i18nResetSelection }}</span>
              </button>
            </div>
          </div>
        </div>

        <div
          v-else-if="searchStore.searchOpenType === 'accommodation'"
          key="accommodation"
          class="m-3"
        >
          <div class="flex flex-col">
            <CiFilterBoolean
              key="pitch"
              class="my-2"
              :value="searchStore.searchParams.acco_type === 'pitch' || searchStore.searchParams.acco_type === 'all'"
              :name="i18nLabelPitch"
              :label="i18nLabelPitch"
              @input="onAccommodationChange($event, 'pitch')"
            >
              <img
                src="~/assets/svg/icon-pitch.svg"
                class="mr-4"
                :alt="`icon ${i18nLabelPitch}`"
              />
            </CiFilterBoolean>
            <CiFilterBoolean
              key="rentals"
              class="my-2"
              :value="searchStore.searchParams.acco_type === 'acco' || searchStore.searchParams.acco_type === 'all'"
              :name="i18nLabelRentals"
              :label="i18nLabelRentals"
              @input="onAccommodationChange($event, 'acco')"
            >
              <img
                src="~/assets/svg/icon-cabin.svg"
                class="mr-3"
                :alt="`icon ${i18nLabelRentals}`"
              />
            </CiFilterBoolean>
          </div>
        </div>

        <div
          v-else-if="searchStore.searchOpenType === 'traveller'"
          key="traveller"
          class="m-3"
        >
          <!-- guests -->
          <CiGuest
            class="mb-4"
            :amount="searchStore.searchParams.adults"
            :guest="searchUiGuestAdult"
            :max="20"
            :min="1"
            @change="onPersonCountChange"
          >
            <img
              src="~/assets/svg/icon-parents.svg"
              width="30"
              height="30"
              alt="Adults"
            />
          </CiGuest>
          <CiGuest
            :amount="localChildrenCount"
            :guest="searchUiGuestChild"
            :max="20"
            :min="0"
            @change="onPersonCountChange"
          >
            <img
              src="~/assets/svg/icon-children.svg"
              width="25"
              height="25"
              alt="Children"
            />
          </CiGuest>

          <!-- age -->
          <div
            v-if="localChildrenCount > 0"
            class="mt-12"
          >
            <div class="m-0 font-medium">
              {{ $gettext('Age of the children on the day of arrival?') }}
            </div>
            <CiSelect
              v-for="(childAge, idx) in localChildrenCount"
              :key="idx"
              :options="ages"
              class="mt-4 block w-full"
              :value="getChildAge(idx)"
              @change="onChildAgeChange($event, idx)"
            />
          </div>
        </div>
      </transition>
    </div>

    <div class="search-modal__footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="my-4 flex justify-between">
              <button
                v-if="searchStore.searchOpenType !== 'traveller' && !searchStore.searchTouched"
                class="button p-0 text-primary"
                type="button"
                @click="onSkip(searchStore.searchOpenType)"
              >
                <span>{{ $pgettext('Search flyout action skip', 'Skip') }}</span>
              </button>

              <CiButton
                v-if="
                  searchStore.searchOpenType !== 'traveller'
                    && (!searchStore.searchTouched || route.name === 'homeLanguage')
                "
                class="button button--primary ml-auto"
                @click.prevent="onNext(searchStore.searchOpenType)"
              >
                <span>{{ $pgettext('Search flyout action next', 'Next') }}</span>
              </CiButton>

              <CiButton
                v-else
                class="button button--warning icon-absolute w-full"
                :icon="faSearch"
                @click.prevent="onSubmit(searchStore.searchOpenType)"
              >
                <span
                  key="search"
                  class="search__submit-button-text"
                >{{
                  $pgettext('Search button text', 'Search')
                }}</span>
              </CiButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.search-modal__content {
  flex: 1;
  overflow: auto;
  overflow-x: hidden;
}

.search-modal__footer {
  border-top: 1px solid $light-gray-20;
}

.flex-days-container {
  @media (min-width: 350px) {
    flex-wrap: nowrap !important;
  }
  .filter-boolean {
    @media (max-width: 349px) {
      margin: 0.25rem 0 !important;
    }
  }
}
</style>
