<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCounterProps {
  canDecrease?: boolean;
  canIncrease?: boolean;
  value: number;
}
const props = withDefaults(defineProps<CiCounterProps>(), {
  canDecrease: true,
  canIncrease: true,
});

const emit = defineEmits<{
  change: [value: number];
}>();
</script>

<template>
  <div class="flex items-center">
    <button
      aria-label="decrease"
      class="button count relative flex size-10 justify-center rounded border border-primary p-0 before:absolute before:left-2/4 before:top-1/2 before:h-[3px] before:w-[13px] before:-translate-x-2/4 before:-translate-y-2/4 before:bg-primary before:content-['']"
      :class="{
        'cursor-not-allowed border !border-gray-40  !bg-gray-10 before:bg-gray-light after:bg-gray-light':
          !props.canDecrease,
      }"
      type="button"
      :disabled="!canDecrease"
      @click="emit('change', value - 1)"
    />

    <input
      type="text"
      class="count__input mx-px my-0 w-[60px] appearance-none border-0 bg-transparent text-center text-xl focus:outline-none"
      name="input"
      :class="{ 'text-gray-light': props.value === 0 }"
      :value="props.value"
      readonly
      @input="emit('change', parseInt(($event?.target as HTMLInputElement)?.value))"
    />

    <button
      aria-label="increase"
      class="button count before:top-2/4; relative flex size-10 justify-center rounded border border-primary p-0 before:absolute before:left-2/4 before:top-1/2 before:h-[3px] before:w-[13px] before:-translate-x-2/4 before:-translate-y-2/4 before:bg-primary before:content-[''] after:absolute after:left-2/4 after:top-1/2 after:h-[13px] after:w-[3px] after:-translate-x-2/4 after:-translate-y-2/4 after:bg-primary after:content-['']"
      type="button"
      :class="{
        'cursor-not-allowed border !border-gray-40  !bg-gray-10 before:bg-gray-light after:bg-gray-light':
          !props.canIncrease,
      }"
      :disabled="!props.canIncrease"
      @click="emit('change', value + 1)"
    />
  </div>
</template>

<style></style>
