<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiGuestProps {
  guest: any;
  amount?: number;
  min?: number;
  max?: number;
}
const props = withDefaults(defineProps<CiGuestProps>(), {
  amount: 0,
  min: 0,
  max: Infinity,
});

const emit = defineEmits<{
  change: [value: { amount: number; person: any }];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext, $pgettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const name = computed(() => {
  return typeof props.guest.name === 'object' && props.guest.name !== null && props.guest.name !== undefined
    ? $pgettext(props.guest.name.ctx, props.guest.name.key)
    : $gettext(props.guest.name);
});

const description = computed(() => {
  return typeof props.guest.description === 'object'
    && props.guest.description !== null
    && props.guest.description !== undefined
    ? $pgettext(props.guest.description.ctx, props.guest.description.key)
    : $gettext(props.guest.description);
});

/********************
 * FUNCTIONS         *
 ********************/
function onGuestChanged(amount: number) {
  if (amount >= props.max) {
    amount = props.max;
  }
  if (amount <= props.min) {
    amount = props.min;
  }
  emit('change', { amount, person: props.guest });
}
</script>

<template>
  <div class="flex flex-wrap md:flex-nowrap">
    <div class="flex w-[50px] items-center justify-center">
      <slot />
    </div>

    <div class="ml-2 mr-12 flex flex-col justify-center">
      <div class="m-0 p-0 font-medium">
        {{ name }}
      </div>
      <div class="text-small-book m-0 whitespace-nowrap p-0 text-gray">
        {{ description }}
      </div>
    </div>

    <div class="ml-auto mt-4 flex justify-end md:mt-0 md:justify-center">
      <CiCounter
        :value="props.amount"
        :can-increase="props.amount < props.max"
        :can-decrease="props.amount > props.min"
        @change="onGuestChanged"
      />
    </div>
  </div>
</template>

<style></style>
