export default function useLocationUrls() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();

  /********************
   * FUNCTIONS         *
   ********************/
  function getRegionUrl(slug, query) {
    delete query.city;
    delete query.country;
    delete query.federal_state;
    delete query.region;

    if (getRouteBaseName(route) === RBN_SEARCH_MAP) {
      return {
        query: { region: slug, ...query },
      };
    } else {
      return {
        name: RBN_REGION_SEARCH,
        params: {
          regionSlug: slug,
        },
        query,
      };
    }
  }

  function getCityUrl(slug, query) {
    delete query.city;
    delete query.country;
    delete query.federal_state;
    delete query.region;

    if (getRouteBaseName(route) === RBN_SEARCH_MAP) {
      return {
        query: { city: slug, ...query },
      };
    } else {
      return {
        name: RBN_CITY_SEARCH,
        params: {
          citySlug: slug,
        },
        query,
      };
    }
  }

  function getCountryOrFederalStateUrl(slug, query) {
    const { params } = route;

    delete query.country;
    delete query.federal_state;
    delete query.region;

    const name = getRouteBaseName(route);
    const splittedSlug = slug.split('/');

    if (name === RBN_SEARCH_MAP && splittedSlug.length === 1) {
      return { params, query: { country: splittedSlug[0], ...query } };
    } else if (name === RBN_SEARCH_MAP && splittedSlug.length === 2) {
      return { params, query: { country: splittedSlug[0], federal_state: splittedSlug[1], ...query } };
    } else {
      if (splittedSlug.length === 1) {
        return {
          name: RBN_COUNTRY_SEARCH,
          params: {
            countrySlug: splittedSlug[0],
          },
          query,
        };
      }

      if (splittedSlug.length === 2) {
        return {
          name: RBN_FEDERAL_STATE_SEARCH,
          params: {
            countrySlug: splittedSlug[0],
            federalStateSlug: splittedSlug[1],
          },
          query,
        };
      }
    }
  }

  return {
    getCityUrl,
    getCountryOrFederalStateUrl,
    getRegionUrl,
  };
}
