export default function useSearchSuggestions() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const searchStore = useSearchStore();
  const { $gettext, interpolate } = useGettext();

  /********************
   * REFS & VARS       *
   ********************/
  const pointer = ref(-1);
  const showSuggestions = ref(false);

  const hasLocation = computed(() => {
    return searchStore.suggestions.locations?.length > 0;
  });

  const hasCampsite = computed(() => {
    return searchStore.suggestions?.campsites?.length > 0;
  });

  const hasSuggestions = computed(() => {
    return hasLocation.value || hasCampsite.value;
  });

  const i18nSuggestionTitle = computed(() => {
    const translated = $gettext('Campsites for "%{ n }"');
    return interpolate(translated, { n: searchStore.searchParams.q || '' });
  });

  const i18nPromoCampsitesTitle = computed(() => {
    return $gettext('Interesting campsites');
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function pointerForward() {
    if (!hasSuggestions.value) {
      return;
    }

    pointer.value += 1;
    if (pointer.value === searchStore.suggestions?.locations?.length + searchStore.suggestions?.campsites?.length) {
      pointer.value = 0;
    }
  }

  function pointerBackward() {
    if (!hasSuggestions.value) {
      return;
    }

    /* if at start of list, jump to end */
    if (pointer.value === 0 || pointer.value === -1) {
      pointer.value = searchStore.suggestions.campsites.length + searchStore.suggestions.locations.length;
    }

    pointer.value -= 1;
  }

  function pointerOn(index) {
    pointer.value = index;
  }

  return {
    hasCampsite,
    hasLocation,
    hasSuggestions,
    i18nPromoCampsitesTitle,
    i18nSuggestionTitle,
    pointer,
    pointerBackward,
    pointerForward,
    pointerOn,
    showSuggestions,
  };
}
