<script setup lang="ts">
// import { faCity } from '@fortawesome/pro-solid-svg-icons';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiSearchResultListProps {
  active?: number;
  icon?: IconDefinition | null;
  imageProp?: string;
  indexOffset?: number;
  noResultsMessage?: boolean;
  results: any[];
  subtitleProp?: string;
  title: string;
  titleProp: string;
}
const props = withDefaults(defineProps<CiSearchResultListProps>(), {
  active: -1,
  icon: null,
  imageProp: '',
  indexOffset: 0,
  noResultsMessage: false,
  subtitleProp: '',
});

const emit = defineEmits<{
  'highlight-change': [value: any];
  'search-result-list-click': [value: any];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();

/********************
 * WATCHER           *
 ********************/
watch(
  () => props.active,
  (newValue) => {
    if (newValue > -1 && props.results[newValue - props.indexOffset]) {
      emit('highlight-change', props.results[newValue - props.indexOffset]);
    }
  },
);

/********************
 * FUNCTIONS         *
 ********************/
function getCampsiteSubTitle(item) {
  return `${item.city} › ${item.federal_state} › ${item.country}`;
}

function getSubTitle(item) {
  if (!props.subtitleProp) {
    return '';
  }

  if (props.subtitleProp === 'campsite') {
    return getCampsiteSubTitle(item);
  }

  if (props.subtitleProp !== 'subtitle') {
    return item[props.subtitleProp];
  }

  if (item.type === 'country') {
    return $gettext('Country');
  }

  if (item.type === 'federal_state') {
    let subtitle = item.country.name + ' | ';
    if (item.country?.slugs?.de === 'schweiz') {
      subtitle += $gettext('Canton');
    } else {
      subtitle += $gettext('Federal State');
    }
    return subtitle;
  }

  if (item.type === 'region') {
    return item.country.name + ' | ' + $gettext('Region');
  }

  if (item.type === 'city') {
    let data = '';
    if (item.federal_state) {
      data = item.federal_state.name + ' › ';
    }
    data += item.country.name + ' | ' + $gettext('City');
    return data;
  }

  return '';
}

function isImage(iconField: string | undefined) {
  if (!iconField) {
    return false;
  }
  return iconField.includes('http');
}

function onClickItem(item) {
  emit('search-result-list-click', item);
}
</script>

<template>
  <div class="search__results">
    <div class="pb-6">
      <div
        v-if="props.title.length > 0"
        class="mb-2.5 truncate px-4 font-medium"
        v-html="props.title"
      />
      <ul
        v-if="props.results.length"
        class="m-0 list-none p-0"
      >
        <li
          v-for="(result, index) in props.results"
          :key="`${result.id}-${index}`"
          class="result__list-item cursor-pointer px-4 py-[0.3rem] last:m-0 hover:bg-warning/10 hover:font-medium hover:text-black"
          :class="{
            'result__list-item--highlighted text-medium bg-warning/10 text-black': active === index + props.indexOffset,
            'location': !!result.type,
          }"
          :data-cy="`ci-search-result-item--${result.type || 'campsite'}`"
          @click.stop="onClickItem(result)"
        >
          <div class="result-item flex flex-row">
            <img
              v-if="imageProp && isImage(result[props.imageProp])"
              :src="result[imageProp]"
              class="result-item__image shrink-0"
              :alt="`${result[props.titleProp]} image`"
            />
            <div
              v-if="props.icon && !result.type && (!props.imageProp || !isImage(result[props.imageProp]))"
              class="result-item__icon flex shrink-0 items-center justify-center"
            >
              <CiAwesomeIcon
                :icon="props.icon"
                class="fill-dark"
                ratio="1"
                style="margin-top: -1px"
              />
            </div>
            <div class="result-item__meta block overflow-hidden">
              <div class="result-item__title flex items-center truncate">
                {{ result[props.titleProp] }}
                <span
                  v-if="result.count_campsites"
                  class="ml-1 inline-block rounded-[10rem] bg-dark px-[7px] py-[3px] text-xs font-bold leading-3 text-white"
                >{{ result.count_campsites }}</span>
              </div>
              <div
                v-if="props.subtitleProp"
                class="result-item__subtitle truncate"
              >
                {{ getSubTitle(result) }}
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul
        v-if="!props.results.length && noResultsMessage"
        class="m-0 list-none p-0"
      >
        <li class="last:m-0 hover:bg-warning/10 hover:font-medium hover:text-black">
          <span>{{ $gettext('No results match your search.') }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search__results {
  .result__list-item {
    &.result__list-item--highlighted,
    &:hover {
      .result-item__subtitle {
        color: inherit;
      }

      .result-item__icon {
        @apply bg-white;
      }
    }
  }

  .result__list-item.location {
    &:not(:first-child) {
      @apply mt-2;
    }
    &:not(:last-child) {
      @apply mb-2;
    }
    .result-item__meta {
      @apply ml-[1.65rem];
    }
  }

  .result-item {
    @apply transition-colors duration-200 ease-linear;

    .result-item__image,
    .result-item__icon {
      @apply h-[50px] w-20 overflow-hidden rounded-lg;
    }

    .result-item__icon {
      @apply bg-gray-10;
    }

    .result-item__meta {
      @apply ml-[0.938rem];
    }

    .result-item__subtitle {
      @apply text-xs leading-4 text-gray-light;
    }
  }
}
</style>
